import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='89.84' y='115.19' width='35.62' height='22.5' />
			<rect x='93.41' y='137.69' width='12' height='6.15' />
			<line x1='105.41' y1='142.86' x2='93.41' y2='142.86' />
			<rect x='109.91' y='137.69' width='12' height='6.15' />
			<line x1='109.91' y1='142.86' x2='121.91' y2='142.86' />
			<rect x='93.41' y='109.04' width='12' height='6.15' />
			<line x1='105.41' y1='110.01' x2='93.41' y2='110.01' />
			<rect x='109.91' y='109.04' width='12' height='6.15' />
			<line x1='109.91' y1='110.01' x2='121.91' y2='110.01' />
			<rect x='200.17' y='119.91' width='22.5' height='22.5' />
			<rect x='178.78' y='155.09' width='65.27' height='24' />
			<polyline points='178.78,157.33 181.78,157.33 181.78,176.08 241.05,176.08 241.05,157.33 244.05,157.33' />
			<line x1='221.08' y1='155.09' x2='221.01' y2='176.08' />
			<line x1='201.76' y1='155.09' x2='201.76' y2='176.08' />
			<rect x='182.45' y='170.97' width='57.93' height='4.5' />
			<rect x='190.49' y='90.41' width='45' height='9' />
			<rect x='194.99' y='94.2' width='36' height='1.12' />
			<polyline points='215.99,95.32 215.99,97.01 209.99,97.01 209.99,95.32' />
			<polyline points='209.99,94.2 209.99,92.51 215.99,92.51 215.99,94.2' />
			<rect x='180.78' y='3.48' width='45.75' height='0.75' />
			<polyline points='226.16,18.09 226.16,63.48 181.16,63.48 181.16,35.42' />
			<polyline points='181.16,21.41 181.16,4.23 226.16,4.23 226.16,12.06' />
			<rect x='166.58' y='3.48' width='13.29' height='13.29' />
			<rect x='227.45' y='3.48' width='13.29' height='13.29' />
			<polyline points='222.78,12.76 222.78,5.66 205.16,5.66 205.16,16.42' />
			<polyline points='199.92,17.51 184.53,17.51 184.53,5.66 201.78,5.66 201.78,17.12' />
			<polygon points='226.53,11.99 226.53,17.94 180.78,35.57 180.78,21.48' />
			<rect x='95.41' y='3.48' width='35.46' height='18' />
			<rect x='118.39' y='21.48' width='1.5' height='8.44' />
			<rect x='107.89' y='29.17' width='10.5' height='1.5' />
			<rect x='106.39' y='21.48' width='1.5' height='8.44' />
			<rect x='106.39' y='12.9' width='13.5' height='4.5' />
			<rect x='117.41' y='13.65' width='1.73' height='3' />
			<rect x='107.14' y='13.65' width='9.64' height='3' />
			<polyline points='115.39,7.27 115.39,5.58 111.27,5.58 111.27,7.27' />
			<polyline points='111.27,8.39 111.27,10.08 115.39,10.08 115.39,8.39' />
			<rect x='106.39' y='7.27' width='13.5' height='1.12' />
		</g>
	);
};

export default Furniture;
