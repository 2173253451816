import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='12.35' y='178.8' width='45' height='24' />
			<path
				d='M33.77,200.42l-17.19-2.07c-1.13-0.14-1.98-1.1-1.98-2.23v-10.63c0-1.14,0.85-2.1,1.98-2.23l17.19-2.07
			c0.71-0.09,1.44-0.09,2.15,0l17.19,2.07c1.13,0.14,1.98,1.1,1.98,2.23v10.63c0,1.14-0.85,2.1-1.98,2.23l-17.19,2.07
			C35.21,200.5,34.49,200.5,33.77,200.42z'
			/>
			<rect x='15.09' y='88.94' width='9.76' height='1.48' />
			<rect x='19.97' y='87.46' width='9.76' height='1.48' />
			<rect x='247.2' y='174.14' width='3.38' height='0.75' />
			<rect x='247.2' y='153.5' width='3.38' height='20.65' />
			<rect x='247.2' y='152' width='3.38' height='1.5' />
			<rect x='247.2' y='126.92' width='3.38' height='25.07' />
			<rect x='247.2' y='125.69' width='3.38' height='1.23' />
			<rect x='247.2' y='98.85' width='3.38' height='0.75' />
			<rect x='247.2' y='73.77' width='3.38' height='25.07' />
			<rect x='247.2' y='72.27' width='3.38' height='1.5' />
			<rect x='247.2' y='47.2' width='3.38' height='25.07' />
			<rect x='247.2' y='45.7' width='3.38' height='1.5' />
			<rect x='247.2' y='23.55' width='3.38' height='1.5' />
			<rect x='247.2' y='12.36' width='3.38' height='11.2' />
			<rect x='247.2' y='11.61' width='3.38' height='0.75' />
			<line x1='248.89' y1='72.27' x2='248.89' y2='47.2' />
			<line x1='248.89' y1='25.05' x2='248.89' y2='45.7' />
			<rect x='247.2' y='25.05' width='3.38' height='20.65' />
			<line x1='248.89' y1='174.14' x2='248.89' y2='153.5' />
			<line x1='248.89' y1='152' x2='248.89' y2='126.92' />
			<rect x='272.17' y='124.94' width='4.5' height='0.75' />
			<rect x='250.58' y='124.94' width='4.49' height='0.75' />
			<line x1='28.12' y1='134.66' x2='16.12' y2='134.66' />
			<line x1='18.88' y1='143.14' x2='18.88' y2='142.38' />
			<path d='M17.76,142.38c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='17.76' y1='143.14' x2='17.76' y2='142.38' />
			<path d='M17.58,143.33c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='18.69' y1='142.19' x2='14.47' y2='142.19' />
			<path d='M18.88,142.38c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='18.88' y1='143.14' x2='18.88' y2='142.38' />
			<path d='M18.69,143.33c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='14.47' y1='143.33' x2='18.69' y2='143.33' />
			<path d='M14.47,142.19c-0.08,0-0.14,0.06-0.14,0.14' />
			<line x1='14.33' y1='143.19' x2='14.33' y2='142.33' />
			<path d='M14.33,143.19c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='16.12' y1='150.86' x2='16.12' y2='143.33' />
			<line x1='28.12' y1='150.86' x2='16.12' y2='150.86' />
			<line x1='28.12' y1='134.66' x2='28.12' y2='150.86' />
			<line x1='16.12' y1='142.19' x2='16.12' y2='134.66' />
			<path
				d='M26.85,160.61c0.67,0,1.06,0.07,1.48,0.14c0.54,0.09,1.12,0.2,1.65,0.4c0.55,0.21,1.04,0.53,1.43,0.86
			c0.3,0.25,0.55,0.5,0.73,0.73c0.15,0.19,0.24,0.36,0.35,0.6c0.1,0.23,0.2,0.51,0.28,0.8c0.08,0.31,0.14,0.62,0.17,0.91
			c0.03,0.3,0.03,0.57,0.03,0.91c0,0.32,0,0.7-0.03,1.04c-0.03,0.32-0.08,0.61-0.12,0.87c-0.04,0.22-0.08,0.43-0.15,0.63
			c-0.08,0.21-0.19,0.41-0.32,0.61c-0.18,0.29-0.38,0.58-0.71,0.89c-0.35,0.33-0.84,0.69-1.26,0.92c-0.41,0.22-0.76,0.32-1.1,0.41
			c-0.29,0.08-0.56,0.15-1.04,0.23c-0.39,0.07-0.92,0.13-1.4,0.13'
			/>
			<line x1='26.85' y1='171.67' x2='18.14' y2='171.67' />
			<line x1='26.85' y1='160.61' x2='18.14' y2='160.61' />
			<polyline points='18.14,178.8 18.14,154.8 29.22,154.8 29.22,131.46' />
			<rect x='56.39' y='131.78' width='1.33' height='25.51' />
			<rect x='10.58' y='93.44' width='27.02' height='1.33' />
			<rect x='162.27' y='61.42' width='1.33' height='25.51' />
			<rect x='118.25' y='61.42' width='1.33' height='25.51' />
			<polyline points='65.2,161.08 178.12,161.08 178.13,179.08' />
			<rect x='81.73' y='161.08' width='0.72' height='18' />
			<rect x='64.45' y='161.08' width='0.75' height='18' />
			<polygon points='273.76,11.49 273.76,12.61 282.92,12.61 304.23,186.11 305.36,186.11 283.92,11.49' />
			<rect x='275.22' y='184.8' width='0.47' height='2.62' />
			<rect x='301.99' y='184.8' width='0.47' height='2.62' />
			<rect x='275.69' y='185.74' width='26.31' height='0.75' />
			<line x1='275.22' y1='186.11' x2='273.76' y2='186.11' />
			<line x1='302.46' y1='186.11' x2='304.23' y2='186.11' />
			<rect
				x='139.8'
				y='81.43'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 14.5839 204.3731)'
				width='1.33'
				height='22.5'
			/>
			<rect x='130.46' y='63.15' width='20.44' height='22.69' />
			<rect x='153.85' y='30.17' width='1.48' height='23.26' />
			<rect x='155.35' y='18.53' width='1.48' height='23.26' />
			<rect x='159.37' y='160.71' width='18' height='0.38' />
			<path d='M176.36,160.71c-5.15-1.95-10.84-1.95-15.99,0' />
			<line x1='30.89' y1='77.56' x2='10.58' y2='77.56' />
			<rect x='136.3' y='161.83' width='21' height='15.75' />
			<path d='M146.38,176.46c0,0.23,0.19,0.42,0.42,0.42c0.23,0,0.42-0.19,0.42-0.42' />
			<path
				d='M146.38,175.95c-0.28,0.23-0.32,0.65-0.09,0.93c0.23,0.28,0.65,0.32,0.93,0.09c0.28-0.23,0.32-0.65,0.09-0.93
			c-0.03-0.03-0.06-0.06-0.09-0.09'
			/>
			<polyline points='146.38,176.46 146.38,176.46 146.38,171.08' />
			<line x1='147.22' y1='171.08' x2='147.22' y2='176.46' />
			<polyline points='144.75,177.12 144.75,175.79 146.38,175.79' />
			<line x1='144.75' y1='177.12' x2='146.8' y2='177.12' />
			<path d='M147.22,171.08c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42' />
			<line x1='145.13' y1='3.48' x2='145.13' y2='56.66' />
			<path
				d='M99.1,162.21h17.54c0.13,0,0.23,0.1,0.23,0.23v14.8c0,0.13-0.1,0.23-0.23,0.23H99.1
			c-0.13,0-0.23-0.1-0.23-0.23v-14.8C98.87,162.31,98.98,162.21,99.1,162.21z'
			/>
			<circle cx='111.19' cy='163.53' r='0.68' />
			<circle cx='109' cy='163.53' r='0.68' />
			<circle cx='104.55' cy='163.53' r='0.68' />
			<circle cx='106.75' cy='163.53' r='0.68' />
			<rect x='98.87' y='160.71' width='18' height='0.38' />
			<path d='M115.87,160.71c-5.15-1.95-10.84-1.95-15.99,0' />
		</g>
	);
};

export default Lineworks;
