import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Office: IScene = {
	name: 'Office',
	pan: '0deg',
	hotspot: {
		x: 110,
		y: 60,
	},
	images: {
		scheme1: Scheme1.Office,
		scheme2: Scheme2.Office,
	},
	default: {
		latitude: -0.058141732932693646,
		longitude: 0.04335353105777348,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.42566160651687346,
				longitude: 3.1132043723428775,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.035969096509169685,
					longitude: 3.122421770061704,
				},
			},
		},
	],
};

export default Office;
