import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='82.45' y1='170.08' x2='83.95' y2='170.08' />
			<line x1='86.12' y1='170.08' x2='95.91' y2='170.08' />
			<line x1='97' y1='170.08' x2='98.5' y2='170.08' />
			<line x1='98.5' y1='179.08' x2='98.5' y2='177.58' />
			<line x1='98.5' y1='175.83' x2='98.5' y2='167.96' />
			<polyline points='98.5,167.08 98.5,165.58 100,165.58		' />
			<line x1='101.85' y1='165.58' x2='114.82' y2='165.58' />
			<polyline points='115.75,165.58 117.25,165.58 117.25,167.08		' />
			<line x1='117.25' y1='168.83' x2='117.25' y2='176.71' />
			<line x1='117.25' y1='177.58' x2='117.25' y2='179.08' />
			<line x1='117.25' y1='170.08' x2='118.75' y2='170.08' />
			<line x1='120.78' y1='170.08' x2='175.6' y2='170.08' />
			<line x1='176.62' y1='170.08' x2='178.12' y2='170.08' />
			<line x1='159.37' y1='161.08' x2='159.37' y2='162.58' />
			<line x1='159.37' y1='164.96' x2='159.37' y2='175.65' />
			<polyline points='159.37,176.83 159.37,178.33 160.87,178.33		' />
			<line x1='162.63' y1='178.33' x2='174.99' y2='178.33' />
			<polyline points='175.87,178.33 177.37,178.33 177.37,176.83		' />
			<line x1='177.37' y1='174.46' x2='177.37' y2='163.77' />
			<line x1='177.37' y1='162.58' x2='177.37' y2='161.08' />
			<line x1='30.89' y1='76.06' x2='29.39' y2='76.06' />
			<line x1='27.35' y1='76.06' x2='13.1' y2='76.06' />
			<line x1='12.08' y1='76.06' x2='10.58' y2='76.06' />
			<line x1='143.63' y1='3.48' x2='143.63' y2='4.98' />
			<line x1='143.63' y1='6.91' x2='143.63' y2='54.2' />
			<line x1='143.63' y1='55.16' x2='143.63' y2='56.66' />
		</g>
	);
};

export default Dashed;
