import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '-90deg',
	hotspot: {
		x: 220,
		y: 130,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.3027391866402773,
		longitude: 4.756067407226078,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.14595885444474743,
				longitude: 4.826451127143753,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.03491775282472043,
					longitude: 4.594257465900779,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5911036905259772,
				longitude: 5.514007791394583,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.06959702248094946,
					longitude: 5.155422291993575,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3332597295536268,
				longitude: 5.053713064853641,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.060141332217155785,
					longitude: 4.698801262261645,
				},
			},
		},
	],
};

export default Living;
