import React from '@wordpress/element';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Player from './Player';
import Color from '../features/schemeColor';
import SCSS from './App.module.scss';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: Color.scheme1,
		},
		secondary: {
			main: Color.scheme2,
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<div className={SCSS.App}>
				<Player />
			</div>
		</ThemeProvider>
	);
}

export default App;
