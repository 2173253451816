import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='32.18' y1='130.04' x2='57.72' y2='130.04' />
			<line x1='151.93' y1='88.67' x2='129.43' y2='88.67' />
			<line x1='244.75' y1='24.3' x2='244.75' y2='73.02' />
			<polyline points='247.2,125.69 244.75,125.69 244.75,174.89 245.11,174.89' />
		</g>
	);
};

export default index;
