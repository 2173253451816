import React from '@wordpress/element';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SCSS from './CloseButton.module.scss';

const ExitButton = withStyles((theme) => ({
	root: {
		color: 'var(--wci-white)',
		borderColor: 'rgba(100, 100, 100, 0.5)',
		backgroundColor: 'transparent',
		'&:hover': {
			borderColor: 'rgba(100, 100, 100, 0.5)',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
}))(IconButton);

type Props = {
	close: () => void;
};

const CloseButton = (props: Props) => {
	const {close} = props;
	return (
		<div className={SCSS.closeContainer}>
			<ExitButton onClick={close}>
				<CloseIcon />
			</ExitButton>
		</div>
	);
};

export default CloseButton;
