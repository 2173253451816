import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<rect x='255.07' y='124.94' width='17.1' height='0.75' />
			<path d='M276.67,124.94c-0.41-14.12-11.97-25.35-26.09-25.35' />
			<path d='M32.18,131.78c0,13.59,10.64,24.8,24.21,25.51' />
			<path d='M10.58,120.46c14.41-0.01,26.28-11.3,27.02-25.69' />
			<path d='M187.81,86.93c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M118.25,61.42c-13.57,0.71-24.21,11.92-24.21,25.51' />
			<path d='M151.16,96.24c0.51-1.9,0.77-3.86,0.77-5.82' />
		</g>
	);
};

export default DoorSwings;
