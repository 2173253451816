import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polygon
				points='245.11,0.25 92.18,0.25 88.25,0.25 88.25,11.18 88.25,11.18 88.25,87.18 34.1,87.18 34.1,64.81 
			30.86,64.81 6.44,64.81 6.44,93.44 10.58,93.44 10.58,90.41 11.71,90.41 11.71,86.93 10.58,86.93 10.58,68.56 30.86,68.56 
			30.86,86.93 29.73,86.93 29.73,90.41 94.04,90.41 94.04,86.93 91.48,86.93 91.48,14.41 95.41,14.41 95.41,3.48 130.87,3.48 
			130.87,56.66 121.57,56.66 121.57,86.93 119.58,86.93 119.58,90.41 129.43,90.41 129.43,86.93 126.83,86.93 126.83,60.15 
			153.84,60.15 153.84,86.93 151.93,86.93 151.93,90.41 160.8,90.41 162.27,90.41 162.27,86.93 157.33,86.93 157.33,53.43 
			153.84,53.43 153.84,56.66 136.13,56.66 136.13,3.48 153.84,3.48 153.84,8.4 157.33,8.4 157.33,3.48 244.75,3.48 244.75,24.3 
			247.2,24.3 247.2,11.61 273.76,11.6 273.76,0.25'
			/>
			<polygon
				points='187.81,90.41 187.81,86.93 244.75,86.93 244.75,73.02 247.2,73.02 247.2,99.12 244.75,99.12 
			244.75,90.41'
			/>
			<polygon
				points='6.44,184.25 6.44,180.12 6.44,120.46 10.58,120.46 10.58,127.97 32.18,127.97 32.18,131.46 
			12.35,131.46 12.29,202.8 57.35,202.8 57.35,179.08 59.19,179.08 59.19,131.78 57.72,131.78 57.72,127.97 64.45,127.97 
			64.45,179.08 245.11,179.08 245.11,174.89 250.58,174.89 250.58,179.08 273.76,178.88 273.76,182.12 273.76,186.01 64.45,186.21 
			64.45,206.03 6.44,206.03'
			/>
		</g>
	);
};
export default Walls;
