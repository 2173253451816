import IScene from './IScene';
import Bedroom from './Bedroom';
import Bathroom from './Bathroom';
import Kitchen from './Kitchen';
import Living from './Living';
import Office from './Office';

const Scenes: Array<IScene> = [Kitchen, Living, Bedroom, Office, Bathroom];

export default Scenes;
