import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bathroom: IScene = {
	name: 'Bathroom',
	pan: '0deg',
	hotspot: {
		x: 40,
		y: 170,
	},
	images: {
		scheme1: Scheme1.Bathroom,
		scheme2: Scheme2.Bathroom,
	},
	default: {
		latitude: -0.05652462547185122,
		longitude: 4.672700121725496,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6771888109593283,
				longitude: 0.18573681688032156,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.04625249794857211,
					longitude: 6.249498125940432,
				},
			},
		},
	],
};

export default Bathroom;
