import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='46.2' cy='190.8' r='0.75' />
			<line x1='247.2' y1='125.69' x2='247.2' y2='99.6' />
			<line x1='250.58' y1='125.69' x2='250.58' y2='99.6' />
			<line x1='27.48' y1='135.23' x2='16.68' y2='135.23' />
			<path
				d='M18.42,143.33c0.31,0.34,0.84,0.36,1.18,0.04c0.34-0.31,0.36-0.84,0.04-1.18c-0.31-0.34-0.84-0.36-1.18-0.04
			c-0.01,0.01-0.03,0.03-0.04,0.04'
			/>
			<path d='M15.65,142.19c0-0.1-0.09-0.18-0.19-0.18' />
			<line x1='14.34' y1='142.01' x2='15.46' y2='142.01' />
			<path d='M14.34,142.01c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='14.15' y1='142.62' x2='14.15' y2='142.2' />
			<line x1='14.15' y1='143.32' x2='14.15' y2='142.9' />
			<path d='M14.15,143.32c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='15.46' y1='143.51' x2='14.34' y2='143.51' />
			<path d='M15.46,143.51c0.1,0,0.18-0.08,0.19-0.18' />
			<line x1='13.77' y1='142.9' x2='13.77' y2='142.62' />
			<line x1='14.33' y1='142.9' x2='13.77' y2='142.9' />
			<line x1='13.77' y1='142.62' x2='14.33' y2='142.62' />
			<line x1='16.68' y1='150.3' x2='16.68' y2='143.33' />
			<line x1='27.48' y1='150.3' x2='16.68' y2='150.3' />
			<line x1='27.48' y1='135.23' x2='27.48' y2='150.3' />
			<line x1='16.68' y1='142.19' x2='16.68' y2='135.23' />
			<path
				d='M20.76,160.99c-0.41,0.03-0.57,0.07-0.71,0.11c-0.13,0.04-0.24,0.08-0.35,0.14
			c-0.11,0.06-0.23,0.12-0.32,0.19c-0.1,0.07-0.18,0.13-0.25,0.2c-0.08,0.08-0.15,0.16-0.22,0.26c-0.08,0.12-0.17,0.26-0.24,0.4
			c-0.08,0.16-0.14,0.32-0.19,0.47c-0.05,0.16-0.09,0.31-0.12,0.47c-0.04,0.18-0.07,0.38-0.09,0.57c-0.03,0.24-0.05,0.47-0.06,0.71
			c-0.02,0.31-0.03,0.62-0.03,0.97c-0.01,0.43-0.01,0.91,0.01,1.41c0.01,0.47,0.04,0.95,0.08,1.34c0.03,0.32,0.07,0.56,0.14,0.85
			c0.05,0.19,0.11,0.4,0.18,0.57c0.05,0.15,0.11,0.27,0.17,0.39c0.06,0.11,0.13,0.21,0.23,0.33c0.1,0.12,0.23,0.25,0.36,0.35
			c0.12,0.1,0.24,0.18,0.35,0.24c0.09,0.06,0.18,0.11,0.28,0.15c0.09,0.04,0.19,0.07,0.3,0.09c0.1,0.02,0.19,0.04,0.31,0.06
			c0.12,0.02,0.27,0.04,0.65,0.04'
			/>
			<path
				d='M26.85,160.99c0.3,0,0.53,0.01,0.74,0.04c0.1,0.01,0.2,0.02,0.3,0.04c0.09,0.01,0.19,0.03,0.28,0.05
			c0.1,0.02,0.19,0.03,0.29,0.05c0.1,0.02,0.2,0.04,0.29,0.05c0.1,0.02,0.2,0.04,0.29,0.06c0.1,0.02,0.19,0.04,0.29,0.07
			s0.19,0.05,0.28,0.08s0.18,0.06,0.27,0.09c0.09,0.03,0.17,0.07,0.25,0.11c0.08,0.04,0.16,0.08,0.24,0.12
			c0.16,0.09,0.31,0.18,0.45,0.28s0.28,0.21,0.4,0.31s0.24,0.21,0.34,0.31c0.1,0.1,0.19,0.2,0.26,0.29c0.04,0.05,0.07,0.09,0.1,0.13
			c0.03,0.04,0.06,0.09,0.08,0.13s0.05,0.09,0.08,0.15c0.03,0.05,0.05,0.11,0.08,0.18c0.03,0.07,0.06,0.14,0.08,0.21
			c0.03,0.07,0.05,0.15,0.08,0.23s0.05,0.16,0.07,0.24c0.02,0.08,0.04,0.16,0.06,0.24c0.04,0.16,0.06,0.32,0.08,0.47
			c0.01,0.08,0.02,0.15,0.02,0.22c0.01,0.07,0.01,0.14,0.01,0.22c0.01,0.14,0.01,0.29,0.01,0.46c0,0.17,0,0.35,0,0.54
			c0,0.19-0.01,0.38-0.02,0.55c-0.01,0.09-0.01,0.17-0.02,0.25c-0.01,0.08-0.02,0.16-0.03,0.24c-0.01,0.08-0.02,0.15-0.04,0.23
			c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.07-0.03,0.14-0.04,0.2c-0.01,0.06-0.03,0.12-0.04,0.18
			c-0.01,0.03-0.02,0.05-0.02,0.08c-0.01,0.03-0.02,0.05-0.03,0.08c-0.02,0.05-0.04,0.1-0.07,0.16s-0.06,0.11-0.09,0.17
			c-0.03,0.06-0.07,0.12-0.11,0.17c-0.04,0.06-0.07,0.12-0.11,0.18c-0.04,0.06-0.08,0.12-0.12,0.17s-0.09,0.11-0.14,0.17
			c-0.05,0.06-0.1,0.12-0.16,0.18s-0.12,0.12-0.19,0.18c-0.07,0.06-0.14,0.12-0.22,0.19c-0.15,0.12-0.32,0.25-0.49,0.36
			c-0.08,0.06-0.17,0.11-0.25,0.16c-0.08,0.05-0.16,0.09-0.23,0.13c-0.08,0.04-0.15,0.07-0.22,0.1c-0.07,0.03-0.14,0.06-0.21,0.08
			c-0.07,0.02-0.14,0.05-0.2,0.07c-0.07,0.02-0.14,0.04-0.2,0.06c-0.07,0.02-0.14,0.04-0.21,0.06c-0.07,0.02-0.14,0.04-0.21,0.05
			c-0.07,0.02-0.15,0.04-0.23,0.05c-0.08,0.02-0.17,0.04-0.27,0.05c-0.05,0.01-0.1,0.02-0.16,0.03s-0.11,0.02-0.17,0.03
			c-0.12,0.02-0.25,0.04-0.39,0.05c-0.14,0.02-0.28,0.03-0.42,0.04c-0.14,0.01-0.28,0.02-0.41,0.02'
			/>
			<line x1='26.85' y1='160.99' x2='20.76' y2='160.99' />
			<line x1='26.85' y1='171.3' x2='21.23' y2='171.3' />
			<rect x='6.44' y='93.44' width='4.13' height='27.02' />
			<line x1='81.73' y1='162.96' x2='65.2' y2='162.96' />
			<line x1='130.46' y1='83.32' x2='150.89' y2='83.32' />
			<path d='M174.6,160.71c-4.07-1.2-8.39-1.2-12.46,0' />
			<circle cx='145.02' cy='176.45' r='0.19' />
			<line x1='145.6' y1='177.12' x2='145.6' y2='175.79' />
			<polyline points='147.22,174.58 155.8,174.58 155.8,163.33 137.8,163.33 137.8,174.58 146.38,174.58' />
			<path
				d='M147.09,170.78c0.38-0.16,0.56-0.6,0.41-0.98s-0.6-0.56-0.98-0.41c-0.38,0.16-0.56,0.6-0.41,0.98
			c0.08,0.18,0.22,0.33,0.41,0.41'
			/>
			<line x1='107.87' y1='177.25' x2='107.87' y2='165.18' />
			<line x1='116.03' y1='165.59' x2='112.57' y2='165.59' />
			<line x1='112.33' y1='165.59' x2='108.48' y2='165.59' />
			<rect x='99.11' y='165.18' width='17.53' height='12.07' />
			<path d='M112.57,168.5c0.28-0.05,0.49-0.27,0.54-0.55' />
			<path d='M113.11,167.73c-0.05-0.28-0.26-0.5-0.54-0.55' />
			<path d='M112.33,167.18c-0.28,0.05-0.49,0.27-0.54,0.55' />
			<path d='M111.79,167.95c0.05,0.28,0.26,0.5,0.54,0.55' />
			<path d='M112.57,169.14c0.63-0.06,1.13-0.56,1.18-1.19' />
			<path d='M113.75,167.73c-0.05-0.63-0.55-1.13-1.18-1.19' />
			<path d='M112.33,166.54c-0.63,0.06-1.13,0.56-1.18,1.19' />
			<path d='M111.15,167.95c0.05,0.63,0.55,1.13,1.18,1.19' />
			<path d='M104.34,167.73c-0.05-0.47-0.42-0.85-0.89-0.9' />
			<path d='M103.2,166.83c-0.47,0.06-0.84,0.43-0.89,0.9' />
			<path d='M102.31,167.95c0.05,0.47,0.42,0.85,0.89,0.9' />
			<path d='M103.45,168.85c0.47-0.06,0.84-0.43,0.89-0.9' />
			<path d='M104.99,167.73c-0.05-0.83-0.71-1.49-1.54-1.55' />
			<path d='M103.2,166.18c-0.83,0.06-1.48,0.72-1.54,1.55' />
			<path d='M101.66,167.95c0.05,0.83,0.71,1.49,1.54,1.55' />
			<path d='M103.45,169.5c0.83-0.06,1.48-0.72,1.54-1.55' />
			<path d='M104.73,173.81c-0.05-0.68-0.59-1.23-1.28-1.29' />
			<path d='M103.2,172.52c-0.68,0.06-1.22,0.6-1.28,1.29' />
			<path d='M101.93,174.03c0.05,0.68,0.59,1.23,1.28,1.29' />
			<path d='M103.45,175.32c0.68-0.06,1.22-0.6,1.28-1.29' />
			<path d='M105.18,173.81c-0.06-0.93-0.8-1.68-1.73-1.74' />
			<path d='M103.2,172.07c-0.93,0.06-1.67,0.81-1.73,1.74' />
			<path d='M101.47,174.03c0.06,0.93,0.8,1.68,1.73,1.74' />
			<path d='M103.45,175.77c0.93-0.06,1.67-0.81,1.73-1.74' />
			<path d='M111.8,174.03c0.05,0.27,0.26,0.49,0.53,0.54' />
			<path d='M112.57,174.57c0.27-0.05,0.49-0.27,0.53-0.54' />
			<path d='M113.11,173.81c-0.05-0.27-0.26-0.49-0.53-0.54' />
			<path d='M112.33,173.26c-0.27,0.05-0.49,0.27-0.53,0.54' />
			<path d='M110.44,174.03c0.06,1.02,0.87,1.84,1.89,1.9' />
			<path d='M112.57,175.92c1.02-0.06,1.83-0.88,1.89-1.9' />
			<path d='M114.46,173.81c-0.06-1.02-0.87-1.84-1.89-1.9' />
			<path d='M112.33,171.91c-1.02,0.06-1.83,0.88-1.89,1.9' />
			<path
				d='M110.99,163.13c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6
			c-0.04-0.09-0.11-0.16-0.2-0.2'
			/>
			<line x1='111.39' y1='162.88' x2='111.39' y2='163.94' />
			<line x1='110.99' y1='162.88' x2='110.99' y2='163.94' />
			<path
				d='M108.8,163.13c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6
			c-0.04-0.09-0.11-0.16-0.2-0.2'
			/>
			<line x1='109.2' y1='162.88' x2='109.2' y2='163.94' />
			<line x1='108.8' y1='162.88' x2='108.8' y2='163.94' />
			<path
				d='M104.35,163.13c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6
			c-0.04-0.09-0.11-0.16-0.2-0.2'
			/>
			<line x1='104.35' y1='162.88' x2='104.35' y2='163.94' />
			<line x1='104.75' y1='162.88' x2='104.75' y2='163.94' />
			<path
				d='M106.55,163.13c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6
			c-0.04-0.09-0.11-0.16-0.2-0.2'
			/>
			<line x1='106.55' y1='162.88' x2='106.55' y2='163.94' />
			<line x1='106.95' y1='162.88' x2='106.95' y2='163.94' />
			<rect x='103.45' y='174.03' width='3.82' height='2.93' />
			<rect x='99.71' y='174.03' width='3.49' height='2.93' />
			<rect x='99.71' y='170.98' width='3.49' height='2.83' />
			<rect x='103.45' y='170.98' width='3.82' height='2.83' />
			<rect x='103.45' y='167.95' width='3.82' height='2.81' />
			<rect x='99.71' y='167.95' width='3.49' height='2.81' />
			<rect x='99.71' y='165.59' width='3.49' height='2.14' />
			<rect x='103.45' y='165.59' width='3.82' height='2.14' />
			<rect x='108.48' y='165.59' width='3.85' height='2.14' />
			<rect x='112.57' y='165.59' width='3.46' height='2.14' />
			<rect x='112.57' y='167.95' width='3.46' height='2.81' />
			<rect x='108.48' y='167.95' width='3.85' height='2.81' />
			<rect x='108.48' y='170.98' width='3.85' height='2.83' />
			<rect x='112.57' y='170.98' width='3.46' height='2.83' />
			<rect x='112.57' y='174.03' width='3.46' height='2.93' />
			<rect x='108.48' y='174.03' width='3.85' height='2.93' />
			<path d='M114.1,160.71c-4.07-1.2-8.39-1.2-12.46,0' />
		</g>
	);
};

export default ThinLineworks;
