import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '0deg',
	hotspot: {
		x: 205,
		y: 75,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.059599026058633875,
		longitude: 6.276752622110167,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1399639309793841,
				longitude: 4.36419622108726,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.0592672020738334,
					longitude: 4.179013027065964,
				},
			},
		},
	],
};

export default Bedroom;
