import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '-90deg',
	hotspot: {
		x: 75,
		y: 110,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.050463081290717415,
		longitude: 1.5528045671096904,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7547479909264156,
				longitude: 4.170062229185363,
			},
			target: {
				name: 'Bathroom',
				view: {
					latitude: -0.05652462547185122,
					longitude: 4.672700121725496,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7430258356014137,
				longitude: 0.7607700882589872,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.1582147887595311,
					longitude: 0.7128420292223362,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.22801250063598255,
				longitude: 1.6906321919084413,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.027668460591375954,
					longitude: 1.5759575126268897,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3767036116813969,
				longitude: 1.3314256244599973,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.1055898096012835,
					longitude: 1.0158179665095242,
				},
			},
		},
	],
};

export default Kitchen;
