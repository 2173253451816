import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Balcony}
				id='Balcony'
				points='250.58,11.58 250.58,186.11 305.36,186.11 283.92,11.49'
			/>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='250.58,0.25 250.58,186.11 64.45,186.11 64.45,206.03 6.44,206.03 6.44,64.81 
		34.12,64.81 34.12,87.46 88.15,87.46 88.15,0'
			/>
		</g>
	);
};

export default Backdrop;
